<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Customer Sales Detail" />
      <b-row class="my-1">
        <b-alert
          v-model="showAlert"
          :variant="alertVariant"
          class="w-100"
          dismissible
          >{{ alertMessage }}</b-alert
        >
      </b-row>
      <form @submit.prevent="searchCustomer">
        <div class="row align-items-start">
          <div class="col-lg-12 col-md-12">
            <b-card>
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <b-form-group
                    id="select-report-type"
                    label="Report Type"
                    label-for="report-type-select"
                    class="field-label-form-group mt-2 mb-0">
                    <b-form-select
                      v-model="selectReportType"
                      id="report-type-select"
                      :options="reportTypeOptions"
                      value-field="value"
                      text-field="label" />
                  </b-form-group>
                </div>
              </div>
              <div v-if="selectReportType === 'salesByCategory'">
                <div class="row mt-4">
                  <b-colxx
                    md="3"
                    sm="12">
                    <b-form-group
                      id="client-type-fieldset"
                      label="Category"
                      label-for="client-type-select"
                      class="field-label-form-group">
                      <b-form-select
                        id="client-type-select"
                        v-model="selectCustomerCategory"
                        :options="categoryList"
                        value-field="category_id"
                        text-field="customer_category_name"></b-form-select>
                    </b-form-group>
                  </b-colxx>
                </div>
              </div>
              <div v-if="selectReportType === 'salesByQuarter'">
                <!-- <div class="row">
                  <div class="col-lg-3 col-md-12">Select Quarter, Select Fiscal Year</div>
                </div> -->
              </div>
              <div v-if="selectReportType === 'salesByBrand'">
                <div class="row mt-4">
                  <div class="col-lg-3 col-md-12">
                    <b-form-group
                      id="select-supplier"
                      label="Supplier"
                      label-for="supplier"
                      class="field-label-form-group">
                      <b-form-select
                        id="supplier"
                        v-model="searchSupplier"
                        :options="supplierList"
                        value-field="erp_supplier_id"
                        text-field="name">
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <b-form-group
                    id="select-report-from-year"
                    label="From"
                    label-for="from-date"
                    class="field-label-form-group mt-2 mb-0">
                    <b-form-select
                      id="from-date"
                      label="From"
                      :options="yearOptions"
                      text-field="year"
                      value-field="year"
                      v-model="searchFromDate"
                      name="From" />
                  </b-form-group>
                </div>
                <div class="col-lg-3 col-md-12">
                  <b-form-group
                    id="select-report-to-year"
                    label="To"
                    label-for="to-date"
                    class="field-label-form-group mt-2 mb-0">
                    <b-form-select
                      id="to-date"
                      label="To"
                      :options="yearOptions"
                      text-field="year"
                      value-field="year"
                      v-model="searchToDate"
                      name="From" />
                  </b-form-group>
                </div>
              </div>
              <div class="row align-items-start mt-4">
                <div class="col-lg-12 col-md-12">
                  <b-button
                    size="md"
                    variant="success"
                    :disabled="disableSearch"
                    @click="generateReport()">
                    {{ searchButtonLabel }}
                  </b-button>
                  <b-button
                    size="md"
                    variant="primary"
                    @click="clear()">
                    Clear
                  </b-button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </form>

      <!-- <b-alert
        v-model="showCustomerIdError"
        variant="danger"
        dismissible>
        We had an issue retrieving the customer information. Please try again.
      </b-alert> -->
      <!-- <div v-if="noSearchResults">
        <b-alert
          title="No Results"
          variant="warning"
          show>
          No results found for the selected search criteria.
        </b-alert>
      </div> -->
      <!--  -->
      <!-- Customer Search Results Modal -->
      <!--  -->
      <!-- <b-modal
        id="customer-search-modal"
        v-model="showCustomerSearchResultsModal"
        title="Customer Search Results"
        size="xl"
        centered
        scrollable
        header-bg-variant="default"
        header-text-variant="white"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close>
        <b-table
          id="customer-search-results"
          responsive
          head-variant="dark"
          :items="searchCustomerResults"
          :fields="searchResultsFields"
          per-page="0">
          <template #cell(action)="row">
            <i
              class="fas fa-circle-check text-primary icon-pointer"
              @click="selectCustomer(row.item)" />
          </template>
        </b-table>

        <template #modal-footer="{}">
          <div>
            <b-button
              size="md"
              class="mr-2"
              variant="info"
              @click="showCustomerSearchResultsModal = false">
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal> -->
      <!-- <div class="row my-4">
        <div class="col">
          <div id="pdf-viewer">
            <pdf-viewer
              :file-url="fileUrl"
              file-name="Customer Sales History.pdf"
              @pdf-ready="watchPdfComponent($event)" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  // import PdfViewer from "@/components/PdfViewer.vue"
  // const baseDomain = process.env.VUE_APP_BASE_DOMAIN
  import { utils, writeFileXLSX, writeFile } from "xlsx"

  function formatProductSalesByQuarterReport(data) {
    const excelData = []

    // Dynamically generate quarters based on the report data
    const quarters = []
    data.details.forEach((customer) => {
      customer.sales.forEach((sale) => {
        if (!quarters.includes(sale.quarter)) {
          quarters.push(sale.quarter)
        }
      })
    })

    // Sort quarters in chronological order (assuming format "YYYY QX")
    quarters.sort()

    // Create the header row
    const headerRow = ["Customer Name", "City", "Province", "Territory", "Product Description"]
    quarters.forEach((quarter) => {
      headerRow.push(`${quarter} Qty`)
      headerRow.push(`${quarter} Sales`)
    })

    excelData.push(headerRow)

    // Populate the rows
    data.details.forEach((customer) => {
      // Create a base row for the customer (without sales data)
      const customerRow = [customer.customer_name, customer.city, customer.province, customer.territory]

      excelData.push(customerRow)

      // Now add rows for each product under this customer
      const productMap = {}

      customer.sales.forEach((sale) => {
        sale.product_sales.forEach((product) => {
          if (!productMap[product.itemDescription]) {
            // Initialize a new product row with empty sales data
            productMap[product.itemDescription] = [
              "", // Empty for Customer Name
              "", // Empty for City
              "", // Empty for Province
              "", // Empty for Territory
              product.itemDescription, // Product Description
            ]
            // Add placeholders for each quarter
            quarters.forEach(() => {
              productMap[product.itemDescription].push("", "")
            })
          }

          // Find the index for this quarter
          const quarterIndex = quarters.indexOf(sale.quarter)

          // Insert the quantity and sales data in the correct place
          productMap[product.itemDescription][5 + quarterIndex * 2] = product.itemQuantity
          productMap[product.itemDescription][6 + quarterIndex * 2] = product.sales
        })
      })

      // Add all product rows to the Excel data
      Object.values(productMap).forEach((productRow) => {
        excelData.push(productRow)
      })
    })

    return excelData
  }

  function formatProductSalesByBrandReport(data) {

      const excelData = [];

      // Dynamically generate quarters based on the report data
      const quarters = [];
      data.details.forEach(customer => {
          customer.sales.forEach(sale => {
              if (!quarters.includes(sale.quarter)) {
                  quarters.push(sale.quarter);
              }
          });
      });
      
      // Sort quarters in chronological order (assuming format "YYYY QX")
      quarters.sort();

      // Create the header row
      const headerRow = ["Customer Name", "City", "Province", "Territory", "Product Description"];
      quarters.forEach(quarter => {
          headerRow.push(`${quarter} Qty`);
          headerRow.push(`${quarter} Sales`);
      });
      
      excelData.push(headerRow);

      // Populate the rows
      data.details.forEach(customer => {
          // Create a base row for the customer (without sales data)
          const customerRow = [
              customer.customer_name.trim(),
              customer.city,
              customer.province,
              customer.territory,
          ];

          excelData.push(customerRow);

          // Map to hold product data across quarters
          const productMap = {};

          customer.sales.forEach(sale => {
              sale.product_sales.forEach(product => {
                  if (!productMap[product.itemDescription]) {
                      // Initialize a new product row with empty sales data
                      productMap[product.itemDescription] = [
                          "", // Empty for Customer Name
                          "", // Empty for City
                          "", // Empty for Province
                          "", // Empty for Territory
                          product.itemDescription, // Product Description
                      ];
                      // Add placeholders for each quarter
                      quarters.forEach(() => {
                          productMap[product.itemDescription].push("", "", "");
                      });
                  }

                  // Find the index for this quarter
                  const quarterIndex = quarters.indexOf(sale.quarter);

                  // Insert the quantity and sales data in the correct place
                  productMap[product.itemDescription][5 + quarterIndex * 2] = product.itemQuantity;
                  productMap[product.itemDescription][6 + quarterIndex * 2] = product.sales;
              });
          });

          // Add all product rows to the Excel data
          Object.values(productMap).forEach(productRow => {
              excelData.push(productRow);
          });
      });

      return excelData;
  }

  export default {
    name: "CustomerSalesDetail",
    components: {
      // PdfViewer,
    },

    data() {
      return {
        selectReportType: "salesByCategory",
        reportTypeOptions: [
          { value: "salesByCategory", label: "Retail Sales Report" },
          { value: "salesByQuarter", label: "Quarterly Sales by Product" },
          { value: "salesByBrand", label: "Brand Sales" },
        ],
        searchFromDate: null,
        searchToDate: null,

        supplierList: [],
        searchSupplier: null,

        categoryList: [
          { category_id: 1, customer_category_name: "Active" },
          { category_id: 2, customer_category_name: "Inactive" },
        ],
        selectCustomerCategory: null,

        territoryList: [],
        territoryId: null,

        salesData: [],
        salesDataExport: [],
        salesDataDetail: [],
        salesSaleDetail: [],

        showAlert: false,
        alertMessage: "",
        alertVariant: "",
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        if (this.selectReportType === "") {
          return true
        }
        if (this.selectReportType === "salesByCategory" && !this.selectCustomerCategory) {
          return true
        }
        if (this.selectReportType === "salesByBrand" && !this.searchSupplier) {
          return true
        }
        return false
      },

      searchButtonLabel() {
        if (this.searchingForCustomers) {
          return "Please Wait..."
        }
        return "Generate Report"
      },

      userIsSalesRep() {
        if (this.roles.includes("SLS_REP") && !this.roles.includes("SLS_MGR") && !this.roles.includes("ADMIN")) {
          return true
        }
        return false
      },

      filteredTerritoryList() {
        let list = this.territoryList
        if (this.userIsSalesRep) {
          return list.filter((territory) => territory.sales_rep_id === this.user.user_id)
        }
        return list
      },

      yearOptions() {
        let i = 0
        let years = []
        while (i <= 42) {
          let year = 2008 + i
          years.push({ year: year })
          i += 1
        }
        return years
      },
    },

    created() {},

    watch: {
      selectedSearchBy(newVal, oldVal) {
        if (oldVal !== newVal) {
          // console.log("selectedSearchByNew", newVal, oldVal)
          this.searchCustomerName = ""
          this.customerId = null
          this.territoryId = null
          this.salesRepId = null
          if (this.searchCustomerResults.length > 0) {
            this.searchCustomerResults = []
            document.getElementById("customer-options").innerHTML = ""
          }
        }
      },
    },

    async mounted() {
      document.title = "Customer Sales Reporting"
      this.$store.dispatch("common/showLoader", true)
      // await django_session
      //   .get("erp/customer/territory/list/", {
      //     params: {
      //       page_size: 0,
      //     },
      //   })
      //   .then((response) => {
      //     this.territoryList = response.data
      //   })
      await django_session.get("erp/customer/category/list/").then((response) => {
        this.categoryList = response.data.results
      })

      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => (this.supplierList = response.data))
        .catch((error) => {
          console.log(error.response)
        })
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      generateReport() {
        this.$store.dispatch("common/showLoader", true)
        django_session
          .get("erp/customer/sales-detail-report/", {
            params: {
              reportType: this.selectReportType,
              fromDate: this.searchFromDate,
              toDate: this.searchToDate,
              supplierId: this.searchSupplier,
              categoryId: this.selectCustomerCategory,
            },
          })
          .then((response) => {
            if (this.selectReportType === "salesByQuarter") {
              // console.log("Backend query complete", response.data);
              let formattedData = formatProductSalesByQuarterReport(response.data);
              // console.log(formattedData);
              let fileName = "Product Sales by Quarter.xlsx";

              // Use aoa_to_sheet instead of json_to_sheet
              let workbook = utils.aoa_to_sheet(formattedData);
              let export_file = utils.book_new();
              utils.book_append_sheet(export_file, workbook, "Report");

              // Adjust column widths
              if (!workbook["!cols"]) workbook["!cols"] = [];
              workbook["!cols"][0] = { wch: 15 };
              workbook["!cols"][1] = { wch: 23 };
              workbook["!cols"][2] = { wch: 12 };
              workbook["!cols"][3] = { wch: 12 };
              workbook["!cols"][(4, 50)] = { wch: 15 };

              writeFile(export_file, fileName);
              // console.log("You should be downloading");
            }

            if (this.selectReportType === "salesByCategory") {
              this.salesData = [response.data]
              this.salesDataExport = this.salesData.map((item) => {
                return {
                  Category: item.category,
                  "Report Name": item.reportName,
                  "From Date": item.fromDate,
                  "To Date": item.toDate,
                }
              })

              this.salesDataDetail = this.salesData[0].details.map((item) => {
                return {
                  "Customer Name": item.customer_name,
                  City: item.city,
                  Province: item.province,
                  Territory: item.territory,
                  "Last Invoice Date": item.last_invoice_date,
                }
              })

              this.salesSaleDetail = []
              let salesList = {}

              this.salesData[0].details.forEach((detail) => {
                let salesList = {}
                detail.sales.forEach((sale) => {
                  salesList[sale.fiscalYear] = sale.f_sales_order_total
                })
                this.salesSaleDetail.push(salesList)
              })

              let fileName = "Sales Report for " + response.data.category + ".xlsx"
              let workbook = utils.json_to_sheet(this.salesDataExport)
              let export_file = utils.book_new()
              utils.book_append_sheet(export_file, workbook, "Report")
              utils.sheet_add_json(workbook, this.salesDataDetail, {
                origin: "A5",
              })
              utils.sheet_add_json(workbook, this.salesSaleDetail, {
                origin: "F5",
              })
              if (!workbook["!cols"]) workbook["!cols"] = []
              workbook["!cols"][0] = { wch: 15 }
              workbook["!cols"][1] = { wch: 23 }
              workbook["!cols"][2] = { wch: 12 }
              workbook["!cols"][3] = { wch: 12 }
              workbook["!cols"][4] = { wch: 16 }
              workbook["!cols"][(5, 50)] = { wch: 15 }
              writeFile(export_file, fileName)
              // console.log("You should be downloading")
            }
            if (this.selectReportType === "salesByBrand") {
              let formattedData = formatProductSalesByBrandReport(response.data);
              let fileName = "Product Sales by Brand.xlsx";

              // Use aoa_to_sheet instead of json_to_sheet
              let workbook = utils.aoa_to_sheet(formattedData);
              let export_file = utils.book_new();
              utils.book_append_sheet(export_file, workbook, "Report");

              // Adjust column widths
              if (!workbook["!cols"]) workbook["!cols"] = [];
              workbook["!cols"][0] = { wch: 15 };
              workbook["!cols"][1] = { wch: 23 };
              workbook["!cols"][2] = { wch: 12 };
              workbook["!cols"][3] = { wch: 12 };
              workbook["!cols"][(4, 50)] = { wch: 15 };

              writeFile(export_file, fileName);
              // console.log("You should be downloading");
            }
          })
          .catch((error) => {
            console.log(error)
            this.alertVariant = "danger"
            this.alertMessage = "An error occurred while generating the report. " + error.response.data
            this.showAlert = true
          })
          .finally(() => {
            this.$store.dispatch("common/showLoader", false)
          })
      },

      clear() {
        this.searchFromDate = null
        this.searchToDate = null
        this.searchSupplier = null
        this.selectCustomerCategory = null
        this.selectReportType = ""
        this.alertMessage = ""
        this.alertVariant = ""
        this.showAlert = false
      },

      // formatSalesReport(data) {
      //   console.log("Formatting sales report data", data)
      //   const excelData = []

      //   // Dynamically generate quarters based on the report data
      //   const quarters = []
      //   data.details.forEach((customer) => {
      //     customer.sales.forEach((sale) => {
      //       if (!quarters.includes(sale.quarter)) {
      //         quarters.push(sale.quarter)
      //       }
      //     })
      //   })

      //   // Sort quarters in chronological order (assuming format "YYYY QX")
      //   quarters.sort()

      //   // Create the header row
      //   const headerRow = ["Customer Name", "City", "Province", "Territory"]
      //   quarters.forEach((quarter) => {
      //     headerRow.push(`${quarter} Quantity Sold`)
      //     headerRow.push(`${quarter} Sales Amount`)
      //   })

      //   excelData.push(headerRow)

      //   // Populate the rows
      //   data.details.forEach((customer) => {
      //     // Create a base row for the customer
      //     const customerRow = [customer.customer_name, customer.city, customer.province, customer.territory]

      //     // Initialize sales data placeholders for each quarter
      //     const customerSalesData = quarters.reduce((acc, quarter) => {
      //       acc[quarter] = { quantitySold: 0, salesAmount: "$0.00" }
      //       return acc
      //     }, {})

      //     // Aggregate sales data for the customer by quarter
      //     customer.sales.forEach((sale) => {
      //       if (sale.product_sales.length === 0) {
      //         // No product sales, but still record the overall sales
      //         customerSalesData[sale.quarter].quantitySold = ""
      //         customerSalesData[sale.quarter].salesAmount = sale.f_sales_order_total
      //       } else {
      //         sale.product_sales.forEach((product) => {
      //           customerSalesData[sale.quarter].quantitySold += product.itemQuantity
      //           customerSalesData[sale.quarter].salesAmount = sale.f_sales_order_total
      //         })
      //       }
      //     })

      //     // Fill customer row with aggregated sales data
      //     quarters.forEach((quarter) => {
      //       customerRow.push(customerSalesData[quarter].quantitySold)
      //       customerRow.push(customerSalesData[quarter].salesAmount)
      //     })

      //     excelData.push(customerRow)

      //     // Now add rows for each product under this customer
      //     customer.sales.forEach((sale) => {
      //       sale.product_sales.forEach((product) => {
      //         const productRow = [
      //           "", // Indent (can be handled by Excel formatting)
      //           "",
      //           "",
      //           "",
      //         ]

      //         quarters.forEach((quarter) => {
      //           if (quarter === sale.quarter) {
      //             productRow.push(product.itemQuantity)
      //             productRow.push(product.f_sales)
      //           } else {
      //             productRow.push("") // Empty for other quarters
      //             productRow.push("")
      //           }
      //         })

      //         excelData.push(productRow)
      //       })
      //     })
      //   })

      //   return excelData
      // },
    },
  }
</script>
<style lang="scss" scoped></style>
